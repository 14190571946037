import React, { useEffect } from "react"
import { Container, Row, Col, Navbar, Nav } from "react-bootstrap"
import bds from "./bds"
import "./themes/solar.min.css"

import * as Links from "./links"

import NLink from "./NLink"
import HomePage from "./pages/Home"

function Main() {
	return (
		<>
			<Navbar className="p-2 navbar-dark" bg="dark" expand="sm" >
				<Navbar.Brand><NLink href="/" >Omanom.com</NLink></Navbar.Brand>
				<Navbar.Toggle aria-controls="responsive-navbar-nav" />
				<Navbar.Collapse>
					<Nav>
						<NLink href="/" >Home</NLink>
						<NLink href="/CV" >CV</NLink>
					</Nav>
					<Nav className="justify-content-end" style={{width:"100%"}} >
						<Links.Email />
						<Links.Twitter />
						<Links.Github />
					</Nav>
				</Navbar.Collapse>
			</Navbar>
			<Container style={{ opacity:0.9 }} >
				<Row>
					<Col>
						<HomePage />
					</Col>
				</Row>
			</Container>
			<footer>
				<p> Copyright &copy; Byron Murgatroyd 2022</p>
			</footer>
		</>
	)
}

function App() {
	useEffect(() => {
		bds.init()
	})

	return (
		<>
			<Main/>
		</>
	)
}

export default App
