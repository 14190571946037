import * as THREE from "three"

const app = (function(){

	let particles, isInitialized = false;

	const settings = {
		maxParticles: 50,
		startingOffset: -100,
		startingRandomFactor: 120,
		focalPoint: new THREE.Vector3(50, 50, 0)
	};

	let ThreeJsScene, ThreeJsRenderer, ThreeJsCamera;

	function init(){
		if (isInitialized) {
			return
		}
		isInitialized = true
		initFancyBackground();
		window.onresize = onWindowResize;
		window.onmousemove = onMouseMove;
		onWindowResize();
	}

	function initFancyBackground(){
		ThreeJsRenderer = new THREE.WebGLRenderer();
		ThreeJsRenderer.setSize(document.body.clientWidth, document.body.clientHeight);
		document.body.appendChild(ThreeJsRenderer.domElement);

		ThreeJsCamera = new THREE.PerspectiveCamera(45, document.body.clientWidth / document.body.clientHeight, 0.1, 500);
		ThreeJsCamera.position.set(50, 50, 300);
		ThreeJsCamera.lookAt(settings.focalPoint);

		ThreeJsScene = new THREE.Scene();
		ThreeJsScene.background = new THREE.Color(0x000000);

		const ambientLight = new THREE.AmbientLight( 0x808080 ); // soft white light
		ThreeJsScene.add(ambientLight);

		particles = generateParticles();

		particles.forEach(function(particle){ ThreeJsScene.add(particle.mesh); });
		rotateCamera(-document.body.clientWidth);

		renderFancyBackground();
	}

	class Particle {

		constructor(){
			this.geometry = new THREE.SphereBufferGeometry(5, 32, 16);
			this.material = new THREE.MeshLambertMaterial({
				//color: new THREE.Color(Math.random(), 0, Math.random())
				color: "#073642",
				transparent: true
			});
			this.mesh = new THREE.Mesh(this.geometry, this.material);
			this.reset();
		}

		reset(){
			this.material.opacity = 1 - Math.random()
			const {startingOffset, startingRandomFactor} = settings

			this.mesh.position.x = startingOffset + Math.random() * startingRandomFactor;
			this.mesh.position.y = startingOffset + Math.random() * startingRandomFactor;
			this.mesh.position.z = startingOffset + Math.random() * -startingRandomFactor;

			this.velocity = {
				x: 0.05,
				y: 0.05,
				z: 0.05,
			}

			this.randomSteps = this.getInitRandonStep()
		}

		getInitRandonStep() {
			return Math.floor(Math.random() * 100)
		}

		getImpulseChange() {
			return (0.55 - Math.random()) * 0.2
		}

		implementVelocity() {
			for (const k in this.velocity) {
				this.mesh.position[k] += this.velocity[k];
			}
		}

		increment(){
			this.material.opacity -= Math.random() * 0.0005;

			if (this.randomSteps > 100) {
				this.randomSteps = 0
				this.velocity.x += this.getImpulseChange()
				this.velocity.y += this.getImpulseChange()
				this.velocity.z += this.getImpulseChange()
			}
			this.randomSteps++

			this.implementVelocity()

			if ( this.material.opacity <= 0.1 ){
				this.reset();
			}
		}
	};

	function generateParticles(){
		var ret = [];
		for ( var i = 0 ; i < settings.maxParticles ; i++ ){
			ret.push(new Particle());
		}
		return ret;
	}

	var cameraRotationMatrix = new THREE.Matrix4();

	function renderFancyBackground(){
		ThreeJsRenderer.render(ThreeJsScene, ThreeJsCamera);
		requestAnimationFrame(renderFancyBackground);
		particles.forEach(function(particle){ particle.increment(); });
	}

	function rotateCamera(val){
		cameraRotationMatrix.makeRotationAxis(new THREE.Vector3(0, 1, 0), val / window.innerWidth);
		ThreeJsCamera.position.applyMatrix4(cameraRotationMatrix);
		ThreeJsCamera.lookAt(settings.focalPoint);
	}

	function onWindowResize(){
		ThreeJsCamera.aspect = document.body.clientWidth / document.body.clientHeight;
		ThreeJsCamera.updateProjectionMatrix();
		//ThreeJsCamera.setSize(document.body.clientWidth, document.body.clientHeight);
		ThreeJsRenderer.setSize(document.body.clientWidth, document.body.clientHeight);
	}

	var mouseLastX;

	function onMouseMove(e){
		if ( mouseLastX === undefined )
			return mouseLastX = e.clientX;

		rotateCamera(mouseLastX - e.clientX);
		mouseLastX = e.clientX;
	}

	return {
		init:init,
		onmousemove:onMouseMove,
		onwindowresize:onWindowResize
	};
}());

export default app
