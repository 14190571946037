import TextBlock from "../textBlock"
import { Container, Row, Col, Image } from "react-bootstrap"
import meBoredImg from "../assets/images/me_bored_by_art.png"

import ImageMooseJs from "../assets/images/project_moosejs.png"
import ImageFreecell from "../assets/images/project_freecell.png"
import ImageFunPg from "../assets/images/project_fun_postgres.png"
import ImageDredger from "../assets/images/project_dredger.png"
import ImageJapango from "../assets/images/project_japango.png"

import GithubIcon from "../assets/icons/github.png"
import NpmIcon from "../assets/icons/npm.png"

const projectData = [
	{
		title: "dredger",
		image: ImageDredger,
		description: `
			Made in go while working for Synchronoss, Dredger is a way to convert helm charts into terraform-kubernetes.
			I like to describe it as dark magic as it's quite hacky but the code is really nice.
		`,
		github: "https://github.com/synchronoss/dredger",
	},
	{
		title: "MooseJS",
		image: ImageMooseJs,
		description: `
			This is a nodejs module based on a perl library called Moose.
			The idea is to create a higher-level OOP system within the language itself.
			The way that this module does this is by making liberal use of Javascript's inbuilt Proxy class to spoof objects and perform logic on operations.
			One of the real challenges was creating typed arrays as it involved overriding the default handlers for methods like shift() and push().
		`,
		github: "https://github.com/byronmurg/moosejs",
		npm: "https://www.npmjs.com/package/moosejs",
	},
	{
		title: "pointless-freecell",
		image: ImageFreecell,
		description: `
			I made a freecell game! Freecell is one of the (few) things that I miss about running windows and I hadn't made anything using C++ captures yet so made this.
			I found a novel use for std::unique_ptr to ensure that cards don't get duplicated while dragging and moving.
			It primarily uses SDL for graphical rendering. The artwork was supplied by Lucy Pickford.
		`,
		github: "https://github.com/byronmurg/pointless-freecell",
	},
	{
		title: "fun-with-postgres",
		image: ImageFunPg,
		description: `
			More of a runnable blog than software, this is a github repo where I document cool and sometimes hacky techniques
			that I've discovered while trying to get the most out of my favourite database.
		`,
		github: "https://github.com/byronmurg/fun-with-postgres",
	},
	{
		title: "japango",
		image: ImageJapango,
		description: `
			I made this while living in Japan as a way to improve my vocab with repetition.
			The site is interesting as it relies heavily on local browser storage so I could use while riding the Metro with no phone signal.
		`,
	},
]

function ProjectEntry({ entry, n }) {
	return (
		<Row className="border-bottom pb-4 mb-4">
			<Row>
				<h2>{entry.title}</h2>
			</Row>
			<Row>
				<Col xs={{ order: n % 2 ? "first" : "last" }} md={4}>
					<Image width="100%" src={entry.image} />
				</Col>
				<Col md={8}>
					<Row>
						<TextBlock>{entry.description}</TextBlock>
					</Row>
					<Row className="p-2" xs="auto">
						<Col>
							{entry.github ? (
								<a href={entry.github}>
									<Image width="32px" src={GithubIcon} />
								</a>
							) : (
								""
							)}
						</Col>
						<Col>
							{entry.npm ? (
								<a href={entry.npm}>
									<Image width="32px" src={NpmIcon} />
								</a>
							) : (
								""
							)}
						</Col>
					</Row>
				</Col>
			</Row>
		</Row>
	)
}

export default function HomePage() {
	return (
		<Container fluid>
			<Row className="mt-4" >
				<Col md={8} >
					<h1>Welcome</h1>
					<TextBlock>
						Hello and welcome to omanom.com the website of me, Byron Murgatroyd.
						<br/><br/>
						This is where I present some of the things I have been working on and provide links to my various accounts.
					</TextBlock>
				</Col>
				<Col md={4} >
					<Image src={meBoredImg} width="300rem" roundedCircle thumbnail />
				</Col>
			</Row>
			<Row className="mb-2" >
				<h1>Projects</h1>
			</Row>
			<Row>
				<Col>
					<TextBlock>
						Here are some cool projects that I've been working on.
					</TextBlock>
				</Col>
			</Row>
			{projectData.map((entry, i) => (
				<ProjectEntry key={i} n={i} entry={entry} />
			))}
		</Container>
	)
}
